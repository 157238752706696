import React, {useEffect, useState} from 'react'

import {withAuth} from "@roam-garden/landscaping-toolbox"

import {Box, Container, Link as A} from "theme-ui"
import SectionHeading from '../components/section-heading'
import Layout from '../components/layout'
import {Auth} from '../common/amplify-wrapper'
import {CognitoUser} from 'amazon-cognito-identity-js'
import {domain} from "../config"


const Success = () => {
  const [url, setUrl] = useState("")
  useEffect(() => {
    (async () => {
      const user: CognitoUser = await Auth.currentAuthenticatedUser()
      setUrl(`https://${user.getUsername()}.${domain}/`)
    })()
  }, [])
  return (
    <Layout>
      <Box as="section" id="success" sx={styles.section}>
        <Container sx={{textAlign: "center",}}>
          <SectionHeading
            title="Your garden is growing. It can take up to 15 min. You'll get an email when it goes live"/>

          <A href={url} sx={{fontSize: "1.2em"}}>{url}</A>
        </Container>
      </Box>
    </Layout>)
}

export default withAuth(Success)

const styles = {
  section: {
    pt: '140px',
    pb: [12, null, null, null, null, 15],
  },
}
